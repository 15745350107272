@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#__next {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-image: url(/site/tavern-updated.png);
  background-color: #02080b;
  background-position: center;
	background-size: contain;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: "Alagard";
}

@font-face {
  font-family: "Alagard";
  src: url("/fonts/alagard.ttf") format("truetype");
  font-weight: 100 400;
  font-style: normal;
}

@font-face {
  font-family: "Mooli";
  src: url("/fonts/Mooli-Regular.ttf") format("truetype");
  font-weight: 100 400;
  font-style: normal;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: white;
}
/* ::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
} */
::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

:root {
  --modal-bg-color: #0f0d1e;
}

.filter-modal-parent .MuiCheckbox-root {
  color: white;
}